import { CarModel, postSearchGetPriceRangeByBrands } from "@/api"
import { CardCar } from "@/components/shared/CardCar"
import { homeStore } from "@/store"
import colors from "@/theme/colors"
import theme from "@/theme/theme"
import styled, { CSSObject } from "@emotion/styled"
import { ButtonBase, Typography } from "@mui/material"
import { sendGTMEvent } from "@next/third-parties/google"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { Swiper, SwiperSlide } from "swiper/react"
const RecomendedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  ${theme.breakpoints.down("sm")} {
    padding: 0px;
  }
  background-color: rgba(205, 218, 234, 0.2);
`

const CardsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  max-width: 1040px;

  ${theme.breakpoints.down("sm")} {
    max-width: 100%;
    width: 100%;
  }
  justify-content: center;
  align-items: center;
`

const HeaderWrapper = styled(Typography)(
  () =>
    ({
      fontSize: 34,
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
      },
    }) as CSSObject
)
const SubHeaderWrapper = styled(Typography)(
  () =>
    ({
      fontSize: 18,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    }) as CSSObject
)

const AllModelsWrapper = styled.div`
  margin-top: 20px;

  ${theme.breakpoints.down("sm")} {
    margin-inline-end: auto;
    padding-inline-start: 22px;
    padding-top: 12px;
    padding-bottom: 68px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

interface RecomendedProps {
  mobile?: boolean
}

const StyledSwiper = styled(Swiper)`
  padding-inline-start: 32px;
  .swiper-wrapper {
    margin-bottom: 16px;
  }
`
const LinkWrapper = styled(Link)`
  text-decoration-line: none;
  height: 100%;
  display: block;
`

const CustomeSwiperSlide = styled(SwiperSlide)`
  ${theme.breakpoints.up("md")} {
    width: 250px !important;
  }
  height: auto !important;
`

export const Recomended = ({ mobile }: RecomendedProps) => {
  const { push } = useRouter()
  const brands = homeStore().brands
  const getToAllModels = async () => {
    sendGTMEvent({
      action: "click",
      event: "all_models_button_clicks",
    })
    let selectedIds = brands.map((brand) => brand.id)
    const res = await postSearchGetPriceRangeByBrands(selectedIds)
    if (res) {
      push(
        "/inventory?minPrice=" +
          res.data.body.minPrice +
          "&maxPrice=" +
          res.data.body.maxPrice
      )
    }
  }
  return (
    <RecomendedWrapper>
      <HeaderWrapper
        variant="h2"
        mt={2}
        fontSize={32}
        fontWeight={300}
        mb={mobile ? 2 : 3}
      >
        רכבים שיכולים לעניין אותך
      </HeaderWrapper>
      <SubHeaderWrapper fontWeight={300} mb={mobile ? 2 : 4}>
        100% איכות. 100% הנאה מנהיגה.
      </SubHeaderWrapper>
      <CardsWrapper>
        <StyledSwiper
          spaceBetween={mobile ? 20 : 10}
          slidesPerView={mobile ? 1.4 : 4}
          touchRatio={1}
          slidesOffsetBefore={mobile ? 10 : 0}
          slidesPerGroup={1}
        >
          {homeStore().intrestedCars.map((car: CarModel) => (
            <CustomeSwiperSlide
              onClick={() =>
                sendGTMEvent({
                  action: "click",
                  event: "model_clicks",
                  model_type: car.modelName,
                })
              }
              key={car.id}
            >
              <LinkWrapper href={`/product/${car.id}`}>
                <CardCar car={car} />
              </LinkWrapper>
            </CustomeSwiperSlide>
          ))}
        </StyledSwiper>
      </CardsWrapper>
      <AllModelsWrapper>
        <ButtonBase
          role="link"
          onClick={getToAllModels}
          style={{ color: colors.blue, textDecoration: "underline" }}
        >
          <Row>
            <Typography fontSize={18} fontWeight={700} color={colors.blue}>
              לכל הדגמים
            </Typography>
            <Image
              src="/assets/cheveronLeft.svg"
              alt=""
              width={25}
              height={25}
            />
          </Row>
        </ButtonBase>
      </AllModelsWrapper>
    </RecomendedWrapper>
  )
}
