import React from "react"

interface VideoProps {
  src: string
}

export const Video = React.forwardRef<HTMLVideoElement, VideoProps>(
  ({ src }, ref) => (
    <video
      ref={ref}
      width={"100%"}
      style={{ objectFit: "cover" }}
      autoPlay
      loop
      muted
      controls={false}
      playsInline
      preload="none"
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  )
)
