import { homeStore } from "@/store"
import theme from "@/theme/theme"
import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Image from "next/image"
import { useMemo, useState } from "react"

const FAQWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  ${theme.breakpoints.down("md")} {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    border: 1px solid rgba(230, 230, 230, 1);
  }
  width: 80%;
  max-width: 1280px;
`

const TextWrapper = styled.div`
  max-width: 407px;
  margin: 20px;
`

const AccordionWrapper = styled.div`
  max-width: 607px;
`

const CustomExpandIcon = ({ expanded }: { expanded: boolean }) => {
  return useMemo(
    () =>
      expanded ? (
        <Image src="/assets/minus.svg" alt="" width={25} height={25} />
      ) : (
        <Image src="/assets/add.svg" alt="" width={25} height={25} />
      ),
    [expanded]
  )
}

export const FAQ = () => {
  const [expanded, setExpanded] = useState<string | false>("panel1")
  const faq = homeStore().faq
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <FAQWrapper id="faq">
      <TextWrapper>
        <Typography
          variant="h2"
          textAlign={"start"}
          mt={2}
          fontSize={24}
          fontWeight={300}
        >
          {faq?.title}
        </Typography>
        <Typography mt={2} fontSize={14} fontWeight={300}>
          {faq?.subTitle}
        </Typography>
      </TextWrapper>
      <AccordionWrapper>
        {faq?.faqs?.map((faq) => (
          <Accordion
            key={faq.id}
            expanded={expanded === faq.name}
            onChange={handleChange(faq.name)}
          >
            <AccordionSummary
              expandIcon={<CustomExpandIcon expanded={expanded === faq.name} />}
              aria-controls="panel-content"
              id="panel-header"
            >
              <Typography fontSize={16} fontWeight={400}>
                {faq.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  direction: "rtl", // Set overall direction to RTL for Hebrew
                  textAlign: "right", // Aligns the text based on the content's flow
                }}
                fontSize={14}
                fontWeight={300}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionWrapper>
    </FAQWrapper>
  )
}
