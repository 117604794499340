"use client"
export const scrollToSection = (id: string) => {
  if (typeof window === "undefined") return

  const element = document.getElementById(id)
  if (element) {
    const headerOffset = 200
    const elementPosition = element.getBoundingClientRect().top + window.scrollY
    const offsetPosition = elementPosition - headerOffset

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
  }
}
