"use client"
import { useEffect, useState } from "react"

export const useHash = () => {
  const [hash, setHash] = useState("")

  useEffect(() => {
    if (typeof window === "undefined") return

    const onHashChange = () => {
      setHash(window.location.hash)
    }

    setHash(window.location.hash) // Set the initial hash
    window.addEventListener("hashchange", onHashChange)

    return () => {
      window.removeEventListener("hashchange", onHashChange)
    }
  }, [])

  return hash
}
